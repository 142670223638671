import { Link, useLocation } from "react-router-dom";
import cn from "classnames";
import { PATHNAMES } from "src/constants/routes";
import { ACCOUNT_TABS_ITEMS } from "./constants";

export const AccountTabs = () => {
  const { pathname } = useLocation();

  const isActive = (href: string) =>
    (pathname === PATHNAMES.ACCOUNT &&
      href === PATHNAMES.ACCOUNT_PERSONAl_DATA) ||
    pathname.includes(href);

  return (
    <div className="mt-10 flex">
      {ACCOUNT_TABS_ITEMS.map(({ id, label, href }) => (
        <Link
          className={cn(
            "flex-1 border-b-2 pb-2 text-center text-sm font-semibold",
            {
              "border-black-base": isActive(href),
            }
          )}
          to={href}
          key={id}
        >
          {label}
        </Link>
      ))}
    </div>
  );
};
