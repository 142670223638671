import React, { FC } from "react";
import { Users } from "src/@types/users";
import { About } from "./About";
import { SubscriptionPlans } from "./SubscriptionPlans";

interface Props {
  activeUser: Users;
}

export const ServicePlan: FC<Props> = ({ activeUser }) => {
  return (
    <div className="mt-12 flex gap-10">
      <About activeUser={activeUser} />
      <SubscriptionPlans activeUser={activeUser} />
    </div>
  );
};
