import React, { FC } from "react";
import { Link } from "react-router-dom";
import { Logo } from "src/components/Logo";
import { PATHNAMES } from "src/constants/routes";
import { AuthForm } from "./AuthForm";

export const Auth: FC = () => {
  return (
    <section className="h-full bg-registration-banner bg-cover bg-no-repeat">
      <div className="z-20 h-full w-1/2 rounded-r-40 bg-white-base px-20 py-8">
        <Logo />
        <AuthForm />

        <p className="mt-4 text-center">
          Don&rsquo;t have an acount?
          <Link className="ml-1 text-yellow-base" to={PATHNAMES.SIGN_UP}>
            Sign Up
          </Link>
        </p>
      </div>
    </section>
  );
};
