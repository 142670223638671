export const PATHNAMES = {
  AUTH: "/auth",
  GOOGLE_AUTH: "/auth/google",

  HOME: "/",
  SUBSCRIPTION: "/subscription",

  ACCOUNT: "/account",
  ACCOUNT_PERSONAl_DATA: "/account/personal-data",
  ACCOUNT_BOOKINGS: "/account/bookings",
  ACCOUNT_REVIEW: "/account/review",

  BOOK_CLEANING: "/book-cleaning",

  SIGN_UP: "/auth/sign-up",
  SIGN_IN: "/auth/sign-in",

  NOT_FOUND: "/*",
};
