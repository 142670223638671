import React, { FC } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as MapIcon } from "src/assets/icons/map-pin.svg";
import { MAP_LINK } from "./constants";

export const ContactUs: FC = () => (
  <div>
    <span className="font-open-sans text-2xl text-white-base">Contact Us</span>

    <ul className="mt-4 flex flex-col gap-3 text-white-base">
      <li className="font-open-sans">
        <span className="font-open-sans">
          Our Support and Sales team is available 24 /7 to answer your queries
        </span>
      </li>

      <li>
        <Link
          className="flex gap-3 hover:opacity-90"
          to={MAP_LINK}
          target="_blank"
        >
          <MapIcon />

          <span className="font-open-sans">
            7214 Hwy 78, Ste. 11 Sachse, TX 75048
          </span>
        </Link>
      </li>
    </ul>
  </div>
);
