import React from "react";
import { Navigate } from "react-router-dom";
// import { ScreenLoader } from "../Loader/ScreenLoader";
import useClientStore from "src/store/client-store";
import { PATHNAMES } from "src/constants/routes";
import { ONLY_FOR, OnlyFor } from "./types";

interface Props {
  component: React.FC;
  onlyFor?: OnlyFor;
  redirectUrl?: string;
}

export const PrivateRoute = ({
  component: Component,
  onlyFor = ONLY_FOR.AUTHORIZED,
  redirectUrl = PATHNAMES.HOME,
}: Props) => {
  const isAuthorized = useClientStore((state) => state.isAuthorized);
  const type = useClientStore((state) => state.type);

  const { CLIENT, AUTHORIZED, UNAUTHORIZED } = ONLY_FOR;

  const isUserClient = isAuthorized && type === CLIENT;

  // if (isLoading) return <ScreenLoader />;

  if (isUserClient && onlyFor === CLIENT) {
    return <Component />;
  }

  if (onlyFor === AUTHORIZED && isAuthorized) {
    return <Component />;
  }

  if (onlyFor === UNAUTHORIZED && !isAuthorized) {
    return <Component />;
  }

  return <Navigate to={redirectUrl} replace />;
};
