import { DateAndTime, InformationFilling, TypeOfCleaning } from "./Steps";
import { IStep } from "./types";

export const CLEANING_STEP_ITEMS: IStep[] = [
  {
    id: "1",
    stepComponent: TypeOfCleaning,
    title: "Type Of Cleaning",
    subtitle: "Lorem ipsum dolor sit amet consectetur.",
  },
  {
    id: "2",
    stepComponent: InformationFilling,
    title: "Information Filling",
    subtitle: "Lorem ipsum dolor sit amet consectetur.",
  },
  {
    id: "3",
    stepComponent: DateAndTime,
    title: "Cleaning Date And Time",
    subtitle: "Lorem ipsum dolor sit amet consectetur.",
  },
];
