import React, { FC } from "react";
import useClientStore from "src/store/client-store";
import { Avatar } from "src/components/Avatar";
import { Button } from "src/components/Button";
import { ReactComponent as EditIcon } from "src/assets/icons/edit.svg";
import { Sizes } from "src/@types/sizes";

export const AccountProfileHeader: FC = () => {
  const avatar = useClientStore((state) => state.avatar);
  const subscription = useClientStore((state) => state.subscription);

  return (
    <div className="mt-10 flex flex-col items-center justify-center gap-3">
      <Avatar sizeVariant={Sizes.M} avatarUrl={avatar} />

      <Button className="gap-2 text-yellow-base">
        <span>{subscription}</span>
        <EditIcon />
      </Button>
    </div>
  );
};
