import React, { FC } from "react";
import cn from "classnames";
import { Sizes } from "src/@types/sizes";
import { Button } from "../Button";
import { ButtonVariants } from "../Button/types";
import { TEXT_INPUT_STYLE_VARIANTS } from "../FormField/constants";

interface Props {
  className?: string;
  value: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
}

export const Search: FC<Props> = ({ className, value, onChange }) => (
  <div className={cn("group flex", className)}>
    <input
      className={cn(TEXT_INPUT_STYLE_VARIANTS.primary, "rounded-r-none")}
      placeholder="Enter you postal code"
      value={value}
      onChange={onChange}
    />

    <Button
      className="min-w-35 -translate-x-20 group-active:translate-y-0"
      variant={ButtonVariants.PRIMARY}
      size={Sizes.S}
    >
      Search
    </Button>
  </div>
);
