import Cookies from "js-cookie";
import { instance } from "src/services/api-client";
import { isTokenExpired } from "src/services/interceptors";
import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { NotificationService } from "src/helpers/notifications";
import { AUTH_REFRESH_TOKEN } from "src/constants/cookiesKeys";
import { SubscriptionType } from "src/@types/subscription";
import { Users } from "src/@types/users";
import avatar from "./temp/avatar_temp.png";

interface IClientStore {
  type: Users.CLIENT;
  full_name: string;
  email: string;
  phone: string;
  avatar: string;
  state: string;
  address: string;
  subscription: string;
  getClient: () => void;
  isAuthorized: boolean;
}

const useClientStore = create(
  devtools<IClientStore>((set) => ({
    isAuthorized: false,
    type: Users.CLIENT,
    full_name: "",
    email: "",
    phone: "",
    avatar: avatar,
    state: "",
    address: "",
    subscription: SubscriptionType.BASIC,
    getClient: async () => {
      try {
        const refreshToken = Cookies.get(AUTH_REFRESH_TOKEN);
        const isRefreshTokenExpired = isTokenExpired(refreshToken);

        if (isRefreshTokenExpired) return null;

        const { data } = await instance.get("/api/auth/me");

        set({ isAuthorized: Boolean(data) });

        return data;
      } catch ({ response }) {
        const errorText = response?.data?.error;

        set({ isAuthorized: false });

        NotificationService.error(errorText);
      }
    },
  }))
);

export default useClientStore;
