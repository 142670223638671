import React, { FC } from "react";
import { Link } from "react-router-dom";
import { PATHNAMES } from "src/constants/routes";

export const CopyRight: FC = () => (
  <div className="flex justify-between text-sm font-light text-white-base">
    <span className="font-open-sans">COPYRIGHT &copy; 2024 SPARKLE</span>

    <div className="flex gap-2.5">
      <Link className="font-open-sans" to={PATHNAMES.HOME}>
        TERM OF USE
      </Link>

      <div className="h-full w-px bg-purple-base"></div>

      <Link className="font-open-sans" to={PATHNAMES.HOME}>
        PRIVACY POLICY
      </Link>
    </div>
  </div>
);
