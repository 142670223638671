import * as Yup from "yup";
import { TextInput } from "src/components/FormField/TextInput";
import {
  EMAIL_VALIDATION_SCHEMA,
  PASSWORD_VALIDATION_SCHEMA,
} from "src/constants/formValidation";
import { IRenderFormField } from "src/@types/form";
import { IFormikValues } from "./types";

const LABEL_CLASSNAME = "text-lg text-gray-base";
const PLACEHOLDER = "Write here";

export const PERSONAL_DATA_INITIAL_VALUES: IFormikValues = {
  full_name: "",
  email: "",
  phone: "",
  state: "",
  address: "",
};

export const getIntialValues = () => ({
  full_name: "",
  email: "",
  phone: "",
  state: "",
  address: "",
});

export const PERSONAL_DATA_FORM_FIELDS: IRenderFormField[] = [
  {
    name: "full_name",
    type: "text",
    label: "Name & Surname",
    component: TextInput,
    labelClassName: LABEL_CLASSNAME,
    placeholder: PLACEHOLDER,
  },
  {
    name: "email",
    type: "email",
    label: "Email",
    component: TextInput,
    labelClassName: LABEL_CLASSNAME,
    placeholder: PLACEHOLDER,
  },
  {
    name: "phone",
    type: "tel",
    label: "Phone",
    component: TextInput,
    labelClassName: LABEL_CLASSNAME,
    placeholder: PLACEHOLDER,
  },
  {
    name: "state",
    type: "text",
    label: "State",
    component: TextInput,
    labelClassName: LABEL_CLASSNAME,
    placeholder: PLACEHOLDER,
  },
  {
    name: "address",
    type: "text",
    label: "Address",
    component: TextInput,
    labelClassName: LABEL_CLASSNAME,
    placeholder: PLACEHOLDER,
    fieldClassName: "col-span-2",
  },
];

export const PERSONAL_DATA_FORM_VALIDATION_SCHEMA = Yup.object().shape({
  email: EMAIL_VALIDATION_SCHEMA,
  password: PASSWORD_VALIDATION_SCHEMA,
});
