import { PATHNAMES } from "src/constants/routes";

export const SERVICES_ITEMS = [
  {
    id: 0,
    title: "Basic Cleaning",

    description:
      "Our basic cleaning service covers all the essentials to keep your space tidy and welcoming. Perfect for regular upkeep!",
    url: PATHNAMES.HOME,
  },
  {
    id: 1,
    title: "Deep Cleaning",

    description:
      "Experience a deep clean that tackles dirt and grime in every corner. Ideal for seasonal refreshes or special occasions.",
    url: PATHNAMES.HOME,
  },
  {
    id: 2,
    title: "Post Construction",

    description:
      "Get your newly renovated space ready to enjoy with our expert cleaning service. We thoroughly remove dust and debris for a spotless, fresh finish!",
    url: PATHNAMES.HOME,
  },
  {
    id: 3,
    title: "Move Out Cleaning",

    description:
      "Ensure you get your security deposit back with our move-out cleaning service. We’ll make your old space shine for the next tenant!",
    url: PATHNAMES.HOME,
  },
];
