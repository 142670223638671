import React, { FC } from "react";
import { Link } from "react-router-dom";
import { PATHNAMES } from "src/constants/routes";
import logoImg from "src/assets/images/logo.png";

const ALT_TEXT_LOGO_IMG = "Sparkle";

interface Props {
  linkHref?: string;
}

export const Logo: FC<Props> = ({ linkHref }) => (
  <div>
    <Link className="block w-max" to={linkHref || PATHNAMES.HOME}>
      <img src={logoImg} alt={ALT_TEXT_LOGO_IMG} />
    </Link>
  </div>
);
