import React, { FC } from "react";
import cn from "classnames";

interface Props {
  currentStep: number;
}

export const ProgressBar: FC<Props> = ({ currentStep }) => {
  const getStepClassName = (step: number) =>
    cn(
      "w-8.5 h-8.5 flex items-center justify-center rounded-full bg-gray-ligther font-medium text-black-medium",
      { "bg-yellow-base text-white-base": step <= currentStep }
    );

  const getLineClassName = (
    isStepComplete: boolean,
    isStepHalfComplete: boolean
  ) =>
    cn("bg-gray-ligther h-1.5 w-25 rounded-40", {
      "bg-yellow-base": isStepComplete,
      "relative before:absolute before:bottom-0 before:left-0 before:right-1/2 before:top-0 before:bg-yellow-base before:rounded-l-40":
        isStepHalfComplete,
    });

  return (
    <div className="flex items-center justify-center gap-5">
      <div className={getStepClassName(1)}>
        <span>1</span>
      </div>

      <div
        className={getLineClassName(
          currentStep === 2 || currentStep === 3,
          currentStep === 1
        )}
      />

      <div className={getStepClassName(2)}>
        <span>2</span>
      </div>

      <div className={getLineClassName(currentStep === 3, currentStep === 2)} />

      <div className={getStepClassName(3)}>
        <span>3</span>
      </div>
    </div>
  );
};
