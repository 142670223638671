export const HOW_IT_WORKS_ITEMS = [
  {
    id: 0,
    title: "Register",
    subtitle: "Create an account in minutes.",
  },
  {
    id: 1,
    title: "Choose a Service",
    subtitle: "Select the type of cleaning you need.",
  },
  {
    id: 2,
    title: "Enjoy",
    subtitle: "Relax while we handle the cleaning.",
  },
];
