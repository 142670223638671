export const INDICATORS_ITEMS = [
  {
    id: 0,
    title: "Experienced",
  },
  {
    id: 1,
    title: "Reliable",
  },
  {
    id: 2,
    title: "Skilled & Capable",
  },
  {
    id: 3,
    title: "Flexible",
  },
];
