import React, { FC } from "react";
import { ReactComponent as ArrowIcon } from "src/assets/icons/arrow-right-circle-black.svg";
import { Users } from "src/@types/users";
import { USER_OPTIONS_ITEMS } from "./contants";

interface Props {
  activeUser: Users;
}

export const Options: FC<Props> = ({ activeUser }) => (
  <div className="flex flex-col gap-2.5">
    {USER_OPTIONS_ITEMS[activeUser].map(({ id, description }) => (
      <div key={id} className="flex max-w-96 gap-3">
        <ArrowIcon className="mt-1 min-w-6" />

        <p className="mt-2">{description}</p>
      </div>
    ))}
  </div>
);
