import React, { FC } from "react";
import { Link } from "react-router-dom";
import iconImg from "src/assets/images/home/our-services/icon.png";
import { SERVICES_ITEMS } from "./contants";

export const OurServicesList: FC = () => {
  return (
    <div className="mt-12.5 flex items-center gap-15">
      {SERVICES_ITEMS.map(({ id, title, description, url }) => (
        <div key={id} className="flex flex-col gap-4">
          <div className="h-16 w-20">
            <img src={iconImg} alt={title} />
          </div>

          <span className="text-2xl font-semibold text-black-ligth">
            {title}
          </span>

          <p>{description}</p>

          <Link
            className="text-sm font-bold text-orange-strong transition-all duration-200 hover:text-orange-base"
            to={url}
          >
            Learn More
          </Link>
        </div>
      ))}
    </div>
  );
};
