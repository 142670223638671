import React, { FC } from "react";
import cn from "classnames";
import { Users } from "src/@types/users";
import { Button } from "../Button";

const DEFAULT_BUTTON_CLASSNAME =
  "flex-1 text-sm font-semibold py-4 rounded-full";

interface Props {
  activeUser: Users;
  setActiveUser: React.Dispatch<React.SetStateAction<Users>>;
}

export const ToggleButton: FC<Props> = ({ activeUser, setActiveUser }) => (
  <div className="flex w-full max-w-80 rounded-full border border-black-ligth">
    <Button
      className={cn(DEFAULT_BUTTON_CLASSNAME, {
        "bg-blue-dark text-white-base": activeUser === Users.CLIENT,
      })}
      onClick={() => setActiveUser(Users.CLIENT)}
    >
      For Customers
    </Button>

    <Button
      className={cn(DEFAULT_BUTTON_CLASSNAME, {
        "bg-blue-dark text-white-base": activeUser === Users.CLINER,
      })}
      onClick={() => setActiveUser(Users.CLINER)}
    >
      For Cliners
    </Button>
  </div>
);
