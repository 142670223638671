import React, { FC, useState } from "react";
import cn from "classnames";
import { Button } from "src/components/Button";
import { ReactComponent as ArrowIcon } from "src/assets/icons/arrow-down.svg";
import { ReactComponent as InfoIcon } from "src/assets/icons/info.svg";
import { Users } from "src/@types/users";
import { Options } from "./Options";

const IMPORTANT_ITEM_CLASS = "relative mt-2 before:absolute before:-left-4";

interface Props {
  activeUser: Users;
}

export const About: FC<Props> = ({ activeUser }) => {
  const [isVisibleImportant, setIsVisibleImportant] = useState(false);

  const handleToggleImportant = () => {
    setIsVisibleImportant(!isVisibleImportant);
  };

  return (
    <div className="flex-1 rounded-2xl bg-red-ligther p-10">
      <h3 className="text-46 font-semibold text-black-ligth">Service plan</h3>

      <p className="text-black-ligth">
        Faucibus commodo a aenean et sit quisque ipsum. Consequat eu id ut dolor
        felis quis.
      </p>

      <hr className="mb-5 mt-7.5" />
      <Options activeUser={activeUser} />
      <hr className="mb-5 mt-7.5" />

      <Button
        className="w-full !justify-between"
        onClick={handleToggleImportant}
      >
        <div className="flex gap-3">
          <InfoIcon />
          <span>Important</span>
        </div>

        <ArrowIcon />
      </Button>

      {isVisibleImportant && (
        <div className="ml-5 mt-3.5">
          <p className={cn("before:content-['1.']", IMPORTANT_ITEM_CLASS)}>
            The platform does not charge a commission on each transaction.
          </p>
          <p className={cn("before:content-['2.']", IMPORTANT_ITEM_CLASS)}>
            Cleaners cannot advertise their websites.
          </p>
          <p className={cn("before:content-['3.']", IMPORTANT_ITEM_CLASS)}>
            The agreement prohibits clients and cleaners from working directly
            outside the platform - violations result in blocking.
          </p>
        </div>
      )}
    </div>
  );
};
