import { PATHNAMES } from "src/constants/routes";

export const NAVIGATION_LINKS = {
  title: "Navigation",
  links: [
    { id: 1, label: "Home", href: PATHNAMES.HOME },
    { id: 2, label: "About Us", href: PATHNAMES.HOME },
    { id: 3, label: "Services", href: PATHNAMES.HOME },
    { id: 4, label: "FAQs", href: PATHNAMES.HOME },
    { id: 5, label: "Contact Us", href: PATHNAMES.HOME },
  ],
};

export const SERVICES_LINKS = {
  title: "Services",
  links: [
    { id: 1, label: "Basic Cleaning", href: PATHNAMES.HOME },
    { id: 2, label: "Deep Cleaning", href: PATHNAMES.HOME },
    { id: 3, label: "Post Construction", href: PATHNAMES.HOME },
    { id: 4, label: "Move Out Cleaning", href: PATHNAMES.HOME },
  ],
};

export const MAP_LINK = "https://maps.app.goo.gl/hDiH35L9nBdhNqnU6";
