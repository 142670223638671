import React, { FC } from "react";
import { Form, FormikConfig, FormikProvider, useFormik } from "formik";
import { Button } from "src/components/Button";
import { ButtonVariants } from "src/components/Button/types";
import { RenderFormFields } from "src/components/RenderFormFields";
import { Sizes } from "src/@types/sizes";
import {
  PERSONAL_DATA_FORM_FIELDS,
  PERSONAL_DATA_FORM_VALIDATION_SCHEMA,
  PERSONAL_DATA_INITIAL_VALUES,
} from "./constants";
import { IFormikValues } from "./types";

export const EditForm: FC = () => {
  const formikProps: FormikConfig<IFormikValues> = {
    initialValues: PERSONAL_DATA_INITIAL_VALUES,
    validationSchema: PERSONAL_DATA_FORM_VALIDATION_SCHEMA,
    onSubmit: (values) => {
      console.log(values);
    },
  };

  const formik = useFormik(formikProps);

  return (
    <FormikProvider value={formik}>
      <Form className="mt-10">
        <div className="grid grid-cols-2 gap-6">
          <RenderFormFields fields={PERSONAL_DATA_FORM_FIELDS} />
        </div>

        <div className="mt-10 flex justify-between">
          <Button size={Sizes.S} variant={ButtonVariants.CANCEL} type="button">
            Cancel
          </Button>

          <Button size={Sizes.S} variant={ButtonVariants.PRIMARY} type="submit">
            Save Changes
          </Button>
        </div>
      </Form>
    </FormikProvider>
  );
};
