import React, { FC } from "react";
import cn from "classnames";
import { useField } from "formik";
import { Error } from "../Error";

interface CheckboxProps {
  name: string;
  label?: string;
  labelClassName?: string;
}

export const Checkbox: FC<CheckboxProps> = ({
  name,
  label,
  labelClassName,
}) => {
  const [field, meta] = useField({ name, type: "checkbox" });

  return (
    <div className="flex items-center gap-2">
      <div
        className={cn(
          "relative flex h-6 w-6 items-center justify-center rounded-md border border-yellow-base",
          {
            "bg-yellow-base": field.checked,
            "bg-transparent": !field.checked,
          }
        )}
      >
        <input
          {...field}
          id={name}
          type="checkbox"
          className="absolute inset-0 cursor-pointer opacity-0"
        />
        <div
          className={cn("h-3 w-3 rounded-sm", {
            "bg-white": field.checked,
            "bg-transparent": !field.checked,
          })}
        />
      </div>

      {label && (
        <label htmlFor={name} className={labelClassName}>
          {label}
        </label>
      )}

      {meta.touched && meta.error && (
        <Error isShownError={true}>{meta.error}</Error>
      )}
    </div>
  );
};
