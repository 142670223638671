import React, { FC } from "react";
import { Logo } from "../Logo";
import { NAVIGATION_LINKS, SERVICES_LINKS } from "./constants";
import { ContactUs } from "./ContactUs";
import { CopyRight } from "./CopyRight";
import { Navigation } from "./Navigation";

interface Props {
  isShown?: boolean;
}

export const Footer: FC<Props> = ({ isShown = true }) => {
  if (!isShown) return null;

  return (
    <footer className="bg-gray-dark">
      <div className="container py-8">
        <div className="flex gap-24">
          <div className="w-[300px]">
            <Logo />

            <p className="mt-3 text-white-base">
              Leo elementum iaculis quam massa vitae odio sed. Morbi tincidunt
              senectus.
            </p>
          </div>

          <div className="mt-8 flex flex-1 gap-15">
            <Navigation {...NAVIGATION_LINKS} />
            <Navigation {...SERVICES_LINKS} />
            <ContactUs />
          </div>
        </div>

        <hr className="mb-8 mt-14 border-yellow-base" />

        <CopyRight />
      </div>
    </footer>
  );
};
