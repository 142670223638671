import React, { FC } from "react";
import { Registration } from "src/page-components/sing-up/Registration";
import { PageWrapper } from "src/components/Layouts/PageWrapper";

const SignUp: FC = () => {
  return (
    <PageWrapper isShownHeader={false} isShownFooter={false}>
      <Registration />
    </PageWrapper>
  );
};

export default SignUp;
