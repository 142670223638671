import React, { FC } from "react";
import { Button } from "src/components/Button";
import { StepControlsProps } from "../types";
import { Title } from "./Title";

export const DateAndTime: FC<StepControlsProps> = ({
  title,
  subtitle,
  onPrevClick,
}) => {
  return (
    <div>
      <Title title={title} subtitle={subtitle} />
      <Button onClick={onPrevClick}>prev</Button>
    </div>
  );
};
