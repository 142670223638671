import * as Yup from "yup";
import { TextInput } from "src/components/FormField/TextInput";
import {
  PASSWORD_VALIDATION_SCHEMA,
  REPEAT_CONFIRM_PASSWORD_VALIDATION_SCHEMA,
} from "src/constants/formValidation";
import { IRenderFormField } from "src/@types/form";
import { IFormikValues } from "./types";

const LABEL_CLASSNAME = "text-lg text-gray-base";
const PLACEHOLDER = "Write here";

export const RESET_PASSWORD_INITIAL_VALUES: IFormikValues = {
  current_password: "",
  new_password: "",
  confirm_password: "",
};

export const RESET_PASSWORD_FORM_FIELDS: IRenderFormField[] = [
  {
    name: "current_password",
    type: "text",
    label: "Current password",
    component: TextInput,
    labelClassName: LABEL_CLASSNAME,
    placeholder: PLACEHOLDER,
  },
  {
    name: "new_password",
    type: "text",
    label: "New password",
    component: TextInput,
    labelClassName: LABEL_CLASSNAME,
    placeholder: PLACEHOLDER,
  },
  {
    name: "confirm_password",
    type: "text",
    label: "Password confirmation",
    component: TextInput,
    labelClassName: LABEL_CLASSNAME,
    placeholder: PLACEHOLDER,
  },
];

export const RESET_PASSWORD_FORM_VALIDATION_SCHEMA = Yup.object().shape({
  current_password: Yup.string().required("This field is required."),
  new_password: PASSWORD_VALIDATION_SCHEMA,
  confirm_password: REPEAT_CONFIRM_PASSWORD_VALIDATION_SCHEMA("new_password"),
});
