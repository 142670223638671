import cleaner_img_1 from "src/page-components/home/OurTeam/OurTeamList/temp/image_cleaner_1.png";
import cleaner_img_2 from "src/page-components/home/OurTeam/OurTeamList/temp/image_cleaner_2.png";
import cleaner_img_3 from "src/page-components/home/OurTeam/OurTeamList/temp/image_cleaner_3.png";
import cleaner_img_4 from "src/page-components/home/OurTeam/OurTeamList/temp/image_cleaner_4.png";
import { PATHNAMES } from "src/constants/routes";
import { ICleaner } from "../types";

export const TEMP_CLEANER_ITEMS: ICleaner[] = [
  {
    id: 0,
    image: cleaner_img_1,
    name: "James Wong",
    description: "Lorem ipsum dolor",
    url: PATHNAMES.HOME,
  },
  {
    id: 1,
    image: cleaner_img_2,
    name: "Sarah Johnson",
    description: "Lorem ipsum dolor",
    url: PATHNAMES.HOME,
  },
  {
    id: 2,
    image: cleaner_img_3,
    name: "David Nguyen",
    description: "Lorem ipsum dolor",
    url: PATHNAMES.HOME,
  },
  {
    id: 3,
    image: cleaner_img_4,
    name: "Samantha Lee",
    description: "Lorem ipsum dolor",
    url: PATHNAMES.HOME,
  },
];
