import React, { FC } from "react";
import { Auth } from "src/page-components/sing-in/Auth";
import { PageWrapper } from "src/components/Layouts/PageWrapper";

const SignIn: FC = () => {
  return (
    <PageWrapper isShownHeader={false} isShownFooter={false}>
      <Auth />
    </PageWrapper>
  );
};

export default SignIn;
