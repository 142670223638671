import * as Yup from "yup";
import { Checkbox } from "src/components/FormField/Checkbox";
import { TextInput } from "src/components/FormField/TextInput";
import {
  EMAIL_VALIDATION_SCHEMA,
  PASSWORD_VALIDATION_SCHEMA,
  REPEAT_CONFIRM_PASSWORD_VALIDATION_SCHEMA,
} from "src/constants/formValidation";
import { IRenderFormField } from "src/@types/form";
import { IFormikValues } from "./types";

const LABEL_CLASSNAME = "font-medium text-gray-base";

export const REGISTRATION_INITIAL_VALUES: IFormikValues = {
  email: "",
  password: "",
  confirm_password: "",
  termsAccepted: false,
};

export const REGISTRATION_FORM_FIELDS: IRenderFormField[] = [
  {
    name: "email",
    type: "email",
    label: "Email",
    component: TextInput,
    labelClassName: LABEL_CLASSNAME,
  },
  {
    name: "password",
    type: "password",
    label: "Password",
    component: TextInput,
    labelClassName: LABEL_CLASSNAME,
  },
  {
    name: "confirm_password",
    type: "password",
    label: "Confirm Password",
    component: TextInput,
    labelClassName: LABEL_CLASSNAME,
  },
  {
    name: "termsAccepted",
    type: "checkbox",
    label: "I agree to the",
    component: Checkbox,
    labelClassName: "text-blue-dark",
  },
];

export const REGISTRATION_FORM_VALIDATION_SCHEMA = Yup.object().shape({
  email: EMAIL_VALIDATION_SCHEMA,
  password: PASSWORD_VALIDATION_SCHEMA,
  confirm_password: REPEAT_CONFIRM_PASSWORD_VALIDATION_SCHEMA("password"),
  termsAccepted: Yup.boolean().oneOf(
    [true],
    "You must accept the terms and conditions."
  ),
});
