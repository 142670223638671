import React, { FC } from "react";
import cn from "classnames";
import { Button } from "src/components/Button";
import { ReactComponent as MarkIcon } from "src/assets/icons/mark.svg";
import { ReactComponent as StarIcon } from "src/assets/icons/star.svg";

interface Props {
  id: number;
  name: string;
  price: number;
  isActive: boolean;
  isEven: boolean;
  onClick: (id: number) => void;
}

export const PlanItem: FC<Props> = ({
  id,
  name,
  price,
  isEven,
  isActive,
  onClick,
}) => {
  return (
    <div
      className={cn(
        "flex justify-between rounded-2xl border border-transparent p-10",
        { "!border-black-base": isActive },
        { "bg-red-ligther": !isEven },
        { "bg-blue-ligther": isEven }
      )}
    >
      <div className="flex items-center gap-6">
        <Button
          className="h-7 w-7 rounded-full border bg-white-base disabled:!opacity-100"
          onClick={() => onClick(id)}
          isDisabled={isActive}
        >
          {isActive && <MarkIcon />}
        </Button>

        <div className="flex gap-3">
          <StarIcon />
          <span className="text-purple-base">{name}</span>
        </div>
      </div>

      <span className="text-46 font-medium text-black-ligth">&#36;{price}</span>
    </div>
  );
};
