import React, { FC } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Form, FormikConfig, FormikProvider, useFormik } from "formik";
import { Button } from "src/components/Button";
import { ButtonVariants } from "src/components/Button/types";
import { RenderFormFields } from "src/components/RenderFormFields";
import { PATHNAMES } from "src/constants/routes";
import { Sizes } from "src/@types/sizes";
import {
  REGISTRATION_FORM_FIELDS,
  REGISTRATION_FORM_VALIDATION_SCHEMA,
  REGISTRATION_INITIAL_VALUES,
} from "./constants";
import { IFormikValues } from "./types";

export const RegistrationForm: FC = () => {
  const navigate = useNavigate();

  const formikProps: FormikConfig<IFormikValues> = {
    initialValues: REGISTRATION_INITIAL_VALUES,
    validationSchema: REGISTRATION_FORM_VALIDATION_SCHEMA,
    onSubmit: (values) => {
      console.log(values);
      navigate(PATHNAMES.HOME);
    },
  };

  const formik = useFormik(formikProps);

  return (
    <div className="mt-15">
      <h3>Sign Up</h3>

      <FormikProvider value={formik}>
        <Form className="mt-10">
          <div className="relative flex flex-col gap-6">
            <RenderFormFields fields={REGISTRATION_FORM_FIELDS} />

            <Link
              className="absolute bottom-0 left-36 text-yellow-base"
              to={PATHNAMES.HOME}
            >
              Terms of Service.
            </Link>
          </div>

          <Button
            className="mt-12 w-full"
            size={Sizes.S}
            variant={ButtonVariants.PRIMARY}
            type="submit"
          >
            Sign Up
          </Button>
        </Form>
      </FormikProvider>
    </div>
  );
};
