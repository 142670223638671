export interface ISubscription {
  id: number;
  duration_days: number;
  description: string;
  created_at: string;
  user_type: string;
  price: number;
  name: string;
  is_active: boolean;
  updated_at: string;
}

export enum SubscriptionType {
  BASIC = "Basic Subscription",
}
