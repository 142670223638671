import { PATHNAMES } from "src/constants/routes";

export const ACCOUNT_TABS_ITEMS = [
  {
    id: 0,
    label: "Personal data",
    href: PATHNAMES.ACCOUNT_PERSONAl_DATA,
  },
  {
    id: 1,
    label: "Bookings",
    href: PATHNAMES.ACCOUNT_BOOKINGS,
  },
  {
    id: 2,
    label: "Review",
    href: PATHNAMES.ACCOUNT_REVIEW,
  },
];
