import React, { FC } from "react";
import { SectionHeader } from "src/components/SectionHeader";
import { ReviewsList } from "./ReviewsList";

export const Reviews: FC = () => (
  <section>
    <div className="container flex flex-col items-center gap-16 py-24">
      <SectionHeader
        title="What Our Clients Say"
        subtitle="Testimonial"
        description="Faucibus commodo a aenean et sit quisque ipsum. Consequat eu id ut dolor felis quis. Sagittis a sapien pulvinar etiam."
        isCenter
      />

      <ReviewsList />
    </div>
  </section>
);
