import React, { FC, useState } from "react";
import { Users } from "src/@types/users";
import { SectionHeader } from "../SectionHeader";
import { AvailableServices } from "./AvailableServices";
import { ServicePlan } from "./ServicePlan";
import { ToggleButton } from "./ToggleButton";

export const SubscriptionSection: FC = () => {
  const [activeUser, setActiveUser] = useState(Users.CLIENT);

  return (
    <section>
      <div className="container py-24">
        <div className="flex flex-col items-center gap-6">
          <SectionHeader
            title="Choose the Right Plan for You"
            subtitle="Pricing Table"
            description="3 weeks of cleaning for only $19.99. Book as many times as you like. Cancel anytime."
            isCenter
          />

          <ToggleButton activeUser={activeUser} setActiveUser={setActiveUser} />
        </div>

        <ServicePlan activeUser={activeUser} />
        <AvailableServices />
      </div>
    </section>
  );
};
