import React, { FC } from "react";
import { Link } from "react-router-dom";
import useClientStore from "src/store/client-store";
import { PATHNAMES } from "src/constants/routes";
import { Sizes } from "src/@types/sizes";
import { Avatar } from "../Avatar";
import { Button } from "../Button";
import { ButtonVariants } from "../Button/types";

export const ActionsButtons: FC = () => {
  const isAuthorized = useClientStore((state) => state.isAuthorized);
  const avatar = useClientStore((state) => state.avatar);

  const bookNowHref = isAuthorized
    ? PATHNAMES.BOOK_CLEANING
    : PATHNAMES.SIGN_UP;
  const cleanWithUsHref = isAuthorized ? PATHNAMES.ACCOUNT : PATHNAMES.SIGN_UP;

  return (
    <div className="flex items-center gap-4">
      <Link to={bookNowHref}>
        <Button size={Sizes.S} variant={ButtonVariants.OPACITY_PRIMARY}>
          Book Now
        </Button>
      </Link>

      <div className="h-10 w-px bg-gray-base" />

      <Link to={cleanWithUsHref}>
        <Button
          size={Sizes.S}
          variant={!isAuthorized && ButtonVariants.OPACITY_PRIMARY}
        >
          {isAuthorized ? (
            <Avatar avatarUrl={avatar} />
          ) : (
            <span>Clean With Us</span>
          )}
        </Button>
      </Link>
    </div>
  );
};
