import { Users } from "src/@types/users";

const FOR_CLIENT_OPTIONS = [
  {
    id: 0,
    description: "Enjoy unlimited booking flexibility",
  },
  {
    id: 1,
    description:
      "Choose your preferred cleaner(s) – solo or a team of 2, 3, or 4",
  },
  {
    id: 2,
    description: "View detailed cleaner profiles before booking",
  },
  {
    id: 3,
    description: "Rate your cleaner after each service",
  },
  {
    id: 4,
    description: "Flexibility to cancel bookings anytime",
  },
];

const FOR_CLINER_OPTIONS = [
  {
    id: 0,
    description: "Receive competitive compensation for your services",
  },
  {
    id: 1,
    description: "Work with a flexible schedule tailored to your needs",
  },
  {
    id: 2,
    description: "Access to a wide range of clients and job opportunities",
  },
  {
    id: 3,
    description: "Get detailed feedback and ratings from clients",
  },
  {
    id: 4,
    description: "Utilize an easy-to-use platform for managing bookings",
  },
];

export const USER_OPTIONS_ITEMS: Record<
  Users,
  {
    id: number;
    description: string;
  }[]
> = {
  [Users.CLIENT]: FOR_CLIENT_OPTIONS,
  [Users.CLINER]: FOR_CLINER_OPTIONS,
};
