import React, { FC } from "react";
import useAuthStore from "src/store/auth-store";
import { Button } from "src/components/Button";
import { ButtonVariants } from "src/components/Button/types";

const Bookings: FC = () => {
  const logout = useAuthStore((state) => state.logout);

  const onClick = () => {
    logout();
  };

  return (
    <div>
      <div>Bookings</div>

      <Button onClick={onClick} variant={ButtonVariants.PRIMARY}>
        Logout
      </Button>
    </div>
  );
};

export default Bookings;
