import React, { FC } from "react";
import preViewImg from "src/assets/images/home/video/pre-view.png";

export const Video: FC = () => {
  return (
    <section>
      <img className="w-full" src={preViewImg} alt="" />
    </section>
  );
};
