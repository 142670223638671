import React, { FC } from "react";
import { Logo } from "../Logo";
import { ActionsButtons } from "./ActionsButtons";
import { Navigation } from "./Navigation";

interface Props {
  isShown?: boolean;
}

export const Header: FC<Props> = ({ isShown = true }) => {
  if (!isShown) return null;

  return (
    <header className="bg-blue-dark">
      <div className="container flex items-center justify-between py-3">
        <Logo />

        <div className="flex items-center gap-12">
          <Navigation />
          <ActionsButtons />
        </div>
      </div>
    </header>
  );
};
