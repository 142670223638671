import React, { ReactNode } from "react";

interface Props {
  children: ReactNode;
  activeStep: number;
}

export const ControlledFlow = ({ children, activeStep }: Props) => {
  const currentChild = React.Children.toArray(children)[activeStep];

  if (React.isValidElement(currentChild)) {
    return React.cloneElement(currentChild);
  }

  return <>{children}</>;
};
