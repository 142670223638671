import React, { FC } from "react";
import { CleanerCard } from "./CleanerCard";
import { TEMP_CLEANER_ITEMS } from "./temp/contants";

export const OurTeamList: FC = () => (
  <div className="flex gap-6">
    {TEMP_CLEANER_ITEMS.map(({ id, ...card }) => (
      <CleanerCard key={id} {...card} />
    ))}
  </div>
);
