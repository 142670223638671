import image_avatar_1 from "src/page-components/home/Reviews/ReviewsList/temp/image_avatar_1.png";
import image_avatar_2 from "src/page-components/home/Reviews/ReviewsList/temp/image_avatar_2.png";
import image_avatar_3 from "src/page-components/home/Reviews/ReviewsList/temp/image_avatar_3.png";
import { PATHNAMES } from "src/constants/routes";
import { IReview } from "../types";

export const TEMP_REVIEWS_ITEMS: IReview[] = [
  {
    id: 0,
    avatar: image_avatar_1,
    name: "Lanelle Tausendfreund",
    description: "Lorem ipsum dolor",
    url: PATHNAMES.HOME,
    review:
      "Team was on time, got everything done with smiles the whole time. Glass was sparkling, floors spotless, stove looked new. It was a great way to start our Thanksgiving holiday. Thank you for such great service.",
    rating: 5,
  },
  {
    id: 1,
    avatar: image_avatar_2,
    name: "Shellie Closson",
    description: "Lorem ipsum dolor",
    url: PATHNAMES.HOME,
    review:
      "Sparkle did an outstanding job cleaning my house. The team that cleaned were friendly too. Added bonus. I would highly recommend this company for your cleaning needs",
    rating: 3,
  },
  {
    id: 2,
    avatar: image_avatar_3,
    name: "Michael Chen",
    description: "Lorem ipsum dolor",
    url: PATHNAMES.HOME,
    review:
      "Team was on time, got everything done with smiles the whole time. Glass was sparkling, floors spotless, stove looked new. It was a great way to start our Thanksgiving holiday. Thank you for such great service.",
    rating: 4,
  },
];
