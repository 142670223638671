import React, { FC } from "react";
import { Outlet } from "react-router";
import useClientStore from "src/store/client-store";
import { AccountTabs } from "src/page-components/account/AccountTabs";
import { PageWrapper } from "src/components/Layouts/PageWrapper";

const Account: FC = () => {
  const isAuthorized = useClientStore((state) => state.isAuthorized);

  if (!isAuthorized) return null;

  return (
    <PageWrapper mainClassName="py-25">
      <section>
        <div className="container">
          <h2 className="text-center">Your Account</h2>
          <AccountTabs />
          <Outlet />
        </div>
      </section>
    </PageWrapper>
  );
};

export default Account;
