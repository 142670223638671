import React, { FC } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Form, FormikConfig, FormikProvider, useFormik } from "formik";
import useAuthStore from "src/store/auth-store";
import useClientStore from "src/store/client-store";
import { Button } from "src/components/Button";
import { ButtonVariants } from "src/components/Button/types";
import { RenderFormFields } from "src/components/RenderFormFields";
import { PATHNAMES } from "src/constants/routes";
import { Sizes } from "src/@types/sizes";
import {
  AUTH_FORM_FIELDS,
  AUTH_FORM_VALIDATION_SCHEMA,
  AUTH_INITIAL_VALUES,
} from "./constants";
import { IFormikValues } from "./types";

export const AuthForm: FC = () => {
  const navigate = useNavigate();
  const login = useAuthStore((state) => state.login);
  const getClient = useClientStore((state) => state.getClient);

  const formikProps: FormikConfig<IFormikValues> = {
    initialValues: AUTH_INITIAL_VALUES,
    validationSchema: AUTH_FORM_VALIDATION_SCHEMA,
    onSubmit: (values) => {
      login(values, () => {
        getClient();
        navigate(PATHNAMES.HOME);
      });
    },
  };

  const formik = useFormik(formikProps);

  return (
    <div className="mt-15">
      <h3>Sign In</h3>

      <FormikProvider value={formik}>
        <Form className="mt-10">
          <div className="flex flex-col gap-6">
            <RenderFormFields fields={AUTH_FORM_FIELDS} />
          </div>

          <div className="mt-1 flex justify-end">
            <Link className="text-yellow-base" to={PATHNAMES.HOME}>
              Forget your password
            </Link>
          </div>

          <Button
            className="mt-12 w-full"
            size={Sizes.S}
            variant={ButtonVariants.PRIMARY}
            type="submit"
          >
            Sign In
          </Button>
        </Form>
      </FormikProvider>
    </div>
  );
};
