import React, { FC } from "react";
import { PageWrapper } from "src/components/Layouts/PageWrapper";
import { SubscriptionSection } from "src/components/Subscription";

const Subscription: FC = () => {
  return (
    <PageWrapper>
      <SubscriptionSection />
    </PageWrapper>
  );
};

export default Subscription;
