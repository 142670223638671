import React, { FC } from "react";
import { CleaningStepForm } from "src/page-components/book-cleaning/CleaningStepForm";
import { PageWrapper } from "src/components/Layouts/PageWrapper";

const BookCleaning: FC = () => {
  return (
    <PageWrapper mainClassName="py-25">
      <section>
        <div className="container !overflow-visible">
          <h2 className="text-center">Cleaning Application</h2>
          <CleaningStepForm />
        </div>
      </section>
    </PageWrapper>
  );
};

export default BookCleaning;
