import { ISubscription } from "src/@types/subscription";
import { Users } from "src/@types/users";

const FOR_CLIENT_PLANS: ISubscription[] = [
  {
    id: 1,
    duration_days: 90,
    description: "some",
    created_at: "2024-11-25T04:57:28.146336+00:00",
    user_type: "client",
    price: 69.99,
    name: "3-MONTH",
    is_active: true,
    updated_at: "2024-11-25T04:57:28.146336+00:00",
  },
  {
    id: 2,
    duration_days: 90,
    description: "some",
    created_at: "2024-11-25T04:57:28.146336+00:00",
    user_type: "client",
    price: 149.99,
    name: "6-MONTH",
    is_active: true,
    updated_at: "2024-11-25T04:57:28.146336+00:00",
  },
  {
    id: 3,
    duration_days: 90,
    description: "some",
    created_at: "2024-11-25T04:57:28.146336+00:00",
    user_type: "client",
    price: 299.99,
    name: "12-MONTH",
    is_active: true,
    updated_at: "2024-11-25T04:57:28.146336+00:00",
  },
];

const FOR_CLINER_PLANS: ISubscription[] = [
  {
    id: 4,
    duration_days: 90,
    description: "some",
    created_at: "2024-11-25T04:57:28.146336+00:00",
    user_type: "client",
    price: 29.99,
    name: "3-MONTH",
    is_active: true,
    updated_at: "2024-11-25T04:57:28.146336+00:00",
  },
  {
    id: 5,
    duration_days: 90,
    description: "some",
    created_at: "2024-11-25T04:57:28.146336+00:00",
    user_type: "client",
    price: 99.99,
    name: "6-MONTH",
    is_active: true,
    updated_at: "2024-11-25T04:57:28.146336+00:00",
  },
  {
    id: 6,
    duration_days: 90,
    description: "some",
    created_at: "2024-11-25T04:57:28.146336+00:00",
    user_type: "client",
    price: 199.99,
    name: "12-MONTH",
    is_active: true,
    updated_at: "2024-11-25T04:57:28.146336+00:00",
  },
];

export const USER_PLANS_ITEMS: Record<Users, ISubscription[]> = {
  [Users.CLIENT]: FOR_CLIENT_PLANS,
  [Users.CLINER]: FOR_CLINER_PLANS,
};
