import React, { FC } from "react";
import { AccountProfileHeader } from "src/page-components/account/personal-data/AccountProfileHeader";
import { EditForm } from "src/page-components/account/personal-data/EditForm";
import { ResetPassword } from "src/page-components/account/personal-data/ResetPassword";

const PersonalData: FC = () => {
  return (
    <div>
      <AccountProfileHeader />
      <EditForm />
      <ResetPassword />
    </div>
  );
};

export default PersonalData;
