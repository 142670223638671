import React, { FC } from "react";
import { INDICATORS_ITEMS } from "./contants";

export const Indicators: FC = () => (
  <div className="flex flex-1 flex-col gap-8">
    {INDICATORS_ITEMS.map(({ id, title }) => (
      <div key={id}>
        <div className="flex justify-between">
          <span className="text-xl font-semibold text-black-medium">
            {title}
          </span>

          <span className="text-xl font-semibold text-yellow-base">100%</span>
        </div>
        <div className="mt-3 h-2.5 w-full rounded-20 bg-yellow-base" />
      </div>
    ))}
  </div>
);
