import React, { FC } from "react";

interface Props {
  title: string;
  subtitle: string;
}

export const Title: FC<Props> = ({ title, subtitle }) => (
  <div>
    <p className="text-2xl font-semibold text-black-ligth">{title}</p>
    <p className="mt-2">{subtitle}</p>
  </div>
);
