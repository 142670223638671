import React, { FC } from "react";
import { IOptionSelect, SetOptionSelect } from "src/@types/form";

interface Props {
  option: IOptionSelect;
  setOption: SetOptionSelect;
}

export const SortOption: FC<Props> = ({ option, setOption }) => {
  const onClick = () => setOption(option);

  return (
    <li
      className="cursor-pointer border-t px-4 py-3 transition-all duration-200 hover:bg-black-ligth hover:text-white-base"
      onClick={onClick}
    >
      {option.label}
    </li>
  );
};
