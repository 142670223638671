export const BENEFITS_ITEMS = [
  {
    id: 0,
    title: "Vetted Professionals",
    description: "Only verified and insured cleaners.",
  },
  {
    id: 1,
    title: "Easy Booking:",
    description: "Hassle-free scheduling and payments.",
  },
  {
    id: 2,
    title: "Flexible Options",
    description: "Services available when and where you need them.",
  },
  {
    id: 3,
    title: "Customer Reviews",
    description: "Read real reviews from happy customers.",
  },
];
