import React, { FC } from "react";
import { SectionHeader } from "src/components/SectionHeader";
import bannerImg from "src/assets/images/home/why-choose-us/banner.png";
import { Benefits } from "./Benefits";

export const WhyChooseUs: FC = () => (
  <section className="bg-blue-ligther">
    <div className="container flex gap-15 pt-25">
      <div className="w-full max-w-121.25">
        <img src={bannerImg} alt="Why Choose Us" />
      </div>

      <div>
        <SectionHeader
          title="We Provide Professional Cleaning Services"
          subtitle="Why Choose Us"
          description="Lorem ipsum dolor sit amet consectetur."
        />

        <Benefits />
      </div>
    </div>
  </section>
);
