import React, { FC, useState } from "react";
import { Button } from "src/components/Button";
import { SortingDropdownList } from "src/components/SortDropdownList";
import { IOptionSelect } from "src/@types/form";
import { StepControlsProps } from "../types";
import { Title } from "./Title";

const TEST_OPTIONS: IOptionSelect[] = [
  { label: "Basic Cleaning", value: "basic-cleaning" },
  { label: "Deep Cleaning", value: "deep-cleaning" },
  { label: "Move Out Cleaning", value: "move-out-cleaning" },
  { label: "Post Construction", value: "post-construction" },
];

export const TypeOfCleaning: FC<StepControlsProps> = ({
  title,
  subtitle,
  onNextClick,
}) => {
  const [sortBy, setSortBy] = useState(null);

  return (
    <div>
      <Title title={title} subtitle={subtitle} />
      <Button onClick={onNextClick}>next</Button>

      <SortingDropdownList
        headLabel="Select the type of cleaning"
        options={TEST_OPTIONS}
        activeOption={sortBy}
        setOption={setSortBy}
      />
    </div>
  );
};
