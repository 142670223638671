import React, { FC } from "react";
import { ReviewsCard } from "./ReviewsCard";
import { TEMP_REVIEWS_ITEMS } from "./temp/contants";

export const ReviewsList: FC = () => (
  <div className="flex gap-6">
    {TEMP_REVIEWS_ITEMS.map(({ id, ...card }) => (
      <ReviewsCard key={id} {...card} />
    ))}
  </div>
);
