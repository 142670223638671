import React, { FC, useState } from "react";
import cn from "classnames";
import { ReactComponent as ArrowBottomIcon } from "src/assets/icons/arrow-bottom-yellow.svg";
import { IOptionSelect, SetOptionSelect } from "src/@types/form";
import { Dropdown } from "../Dropdown";
import { SortOption } from "./SortOption";

interface Props {
  className?: string;
  options: IOptionSelect[];
  activeOption: IOptionSelect;
  setOption: SetOptionSelect;
  headLabel?: string;
}

export const SortingDropdownList: FC<Props> = ({
  className,
  options,
  activeOption,
  setOption,
  headLabel,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const headDropdownLabel = (
    <div
      className={cn(
        "rounded-10 flex w-full items-center justify-between gap-2 border bg-white-base px-4 py-3",
        { "rounded-b-none border-yellow-base": isOpen }
      )}
    >
      {activeOption?.label ? (
        <span className="">{activeOption.label}</span>
      ) : (
        <span className="text-sm text-black-medium">
          {headLabel && <span className="text-gray-medium">{headLabel}</span>}
        </span>
      )}

      <ArrowBottomIcon
        className={cn("transition-all duration-200", {
          "rotate-180": isOpen,
        })}
      />
    </div>
  );

  const filteredOptions = activeOption
    ? options.filter(({ value }) => value !== activeOption.value)
    : options;

  const setActiveOption = (option: IOptionSelect) => {
    setOption(option);
    setIsOpen(false);
  };

  return (
    <Dropdown
      className={className}
      head={headDropdownLabel}
      bodyClassName="bg-white-base w-full top-13 z-10 rounded-b-10 border"
      isOpen={isOpen}
      setIsOpen={setIsOpen}
    >
      <ul>
        {filteredOptions.map((option) => (
          <SortOption
            key={option.value}
            option={option}
            setOption={setActiveOption}
          />
        ))}
      </ul>
    </Dropdown>
  );
};
