import React, { FC, useState } from "react";
import { Button } from "src/components/Button";
import { ButtonVariants } from "src/components/Button/types";
import { Sizes } from "src/@types/sizes";
import { Users } from "src/@types/users";
import { USER_PLANS_ITEMS } from "./constants";
import { PlanItem } from "./PlanItem";

interface Props {
  activeUser: Users;
}

export const SubscriptionPlans: FC<Props> = ({ activeUser }) => {
  const plans = USER_PLANS_ITEMS[activeUser];

  const [activePlanId, setActivePlanId] = useState(null);

  const handleOnClickPlan = (id: number) => {
    setActivePlanId(id);
  };

  return (
    <div className="flex-1">
      <div className="flex flex-col gap-3">
        {plans.map((plan, index) => (
          <PlanItem
            key={plan.id}
            isActive={activePlanId === plan.id}
            isEven={index % 2 === 0}
            onClick={handleOnClickPlan}
            {...plan}
          />
        ))}
      </div>

      <div className="mt-10 flex gap-4">
        <Button
          className="w-full"
          size={Sizes.S}
          variant={ButtonVariants.OPACITY_PRIMARY}
        >
          Rate Information
        </Button>

        <Button
          className="w-full"
          size={Sizes.S}
          variant={ButtonVariants.PRIMARY}
        >
          Get Started
        </Button>
      </div>
    </div>
  );
};
