import React, { FC, useEffect } from "react";
import { useRoutes } from "react-router-dom";
import {
  Account,
  BookCleaning,
  Bookings,
  Home,
  NotFound,
  PersonalData,
  Review,
  SignIn,
  SignUp,
  Subscription,
} from "src/pages";
import useClientStore from "src/store/client-store";
import { PrivateRoute } from "src/components/PrivateRoute";
import { PATHNAMES } from "src/constants/routes";

const ROUTES = [
  {
    element: <Home />,
    path: PATHNAMES.HOME,
  },
  {
    element: <Subscription />,
    path: PATHNAMES.SUBSCRIPTION,
  },
  {
    element: <PrivateRoute component={Account} />,
    path: PATHNAMES.ACCOUNT,
    children: [
      {
        element: <PersonalData />,
        index: true,
      },
      {
        element: <PersonalData />,
        path: PATHNAMES.ACCOUNT_PERSONAl_DATA,
      },
      {
        element: <Bookings />,
        path: PATHNAMES.ACCOUNT_BOOKINGS,
      },
      {
        element: <Review />,
        path: PATHNAMES.ACCOUNT_REVIEW,
      },
    ],
  },
  {
    element: <BookCleaning />,
    path: PATHNAMES.BOOK_CLEANING,
  },
  {
    element: <SignUp />,
    path: PATHNAMES.SIGN_UP,
  },
  {
    element: <SignIn />,
    path: PATHNAMES.SIGN_IN,
  },
  {
    element: <NotFound />,
    path: PATHNAMES.NOT_FOUND,
  },
];

const AppRoutes: FC = () => {
  const getClient = useClientStore((state) => state.getClient);

  useEffect(() => {
    getClient();
  }, [getClient]);

  return useRoutes(ROUTES);
};

export default AppRoutes;
