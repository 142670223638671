import React, { FC } from "react";
import { Link } from "react-router-dom";
import { NAVIGATION_LINKS } from "./constants";

export const Navigation: FC = () => (
  <ul className="flex gap-12 text-white-base">
    {NAVIGATION_LINKS.map(({ id, label, href }) => (
      <li key={id}>
        <Link className="hover:opacity-90" to={href}>
          {label}
        </Link>
      </li>
    ))}
  </ul>
);
