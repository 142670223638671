import React, { FC } from "react";
import cn from "classnames";
import { getArrayNumbers } from "src/utils/getArrayNumbers";
import { ReactComponent as StarIcon } from "src/assets/icons/star-rating.svg";

interface Props {
  className?: string;
  rating: number;
}

export const Rating: FC<Props> = ({ className, rating }) => (
  <div className={cn("flex gap-0.5", className)}>
    {getArrayNumbers(rating).map((star) => (
      <StarIcon key={star} />
    ))}
  </div>
);
