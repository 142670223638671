import React, { FC, useState } from "react";
import { CLEANING_STEP_ITEMS } from "./constants";
import { ControlledFlow } from "./ControlledFlow";
import { ProgressBar } from "./ProgressBar";

export const CleaningStepForm: FC = () => {
  const [activeStepIndex, setActiveStepIndex] = useState(0);

  const onNextClick = () => {
    setActiveStepIndex(activeStepIndex + 1);
  };

  const onPrevClick = () => {
    setActiveStepIndex(activeStepIndex - 1);
  };

  return (
    <div className="max-w-175 mx-auto mt-12 rounded-30 bg-blue-ligther px-12 py-12">
      <ProgressBar currentStep={activeStepIndex + 1} />
      <hr className="mb-16 mt-8" />

      <ControlledFlow activeStep={activeStepIndex}>
        {CLEANING_STEP_ITEMS.map(
          ({ id, stepComponent: StepComponent, title, subtitle }) => (
            <StepComponent
              key={id}
              onNextClick={onNextClick}
              onPrevClick={onPrevClick}
              title={title}
              subtitle={subtitle}
            />
          )
        )}
      </ControlledFlow>
    </div>
  );
};
