import React, { FC, useState } from "react";
import { Button } from "src/components/Button";
import { ButtonVariants } from "src/components/Button/types";
import { Search } from "src/components/Search";
import { ReactComponent as StarIcon } from "src/assets/icons/star.svg";
import bannerImg from "src/assets/images/home/hero-section/banner.png";
import { Sizes } from "src/@types/sizes";

export const HeroSection: FC = () => {
  const [searchQuery, setSearchQuery] = useState("");

  return (
    <section>
      <div className="container flex items-center gap-3 pt-3.5">
        <div className="flex-1">
          <div className="flex flex-col gap-3.5">
            <div className="flex items-center gap-5">
              <StarIcon />
              <span className="uppercase text-yellow-base">
                sparkle the Cleaning Services
              </span>
            </div>

            <h1>Cleaning Services For All Your Needs</h1>

            <p className="max-w-122.5">
              Looking for a professional cleaning service? Choose from a wide
              range of vetted cleaners, available for one-time or recurring
              cleanings. Book online in just a few clicks.
            </p>
          </div>

          <div className="mt-5 flex gap-4">
            <Button size={Sizes.M} variant={ButtonVariants.PRIMARY}>
              Book a Cleaner Now
            </Button>

            <Button size={Sizes.M} variant={ButtonVariants.OPACITY_PRIMARY}>
              Become A Cleaner With Us
            </Button>
          </div>

          <div className="mt-10">
            <span className="text-2xl font-semibold text-black-ligth">
              Find your cleaner now!
            </span>

            <Search
              className="mt-2"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
        </div>

        <div className="w-full max-w-130">
          <img src={bannerImg} alt="Sparkle the Cleaning Services" />
        </div>
      </div>
    </section>
  );
};
