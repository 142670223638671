import React, { FC } from "react";
import { ReactComponent as ArrowIcon } from "src/assets/icons/arrow-right-circle-yellow.svg";
import { BENEFITS_ITEMS } from "./contants";

export const Benefits: FC = () => (
  <div className="mt-12.5 flex flex-col gap-5">
    {BENEFITS_ITEMS.map(({ id, title, description }) => (
      <div key={id} className="flex gap-3">
        <ArrowIcon className="mt-1" />

        <div>
          <span className="text-lg font-semibold text-black-ligth">
            {title}
          </span>

          <p className="mt-2">{description}</p>
        </div>
      </div>
    ))}
  </div>
);
