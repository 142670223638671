import React, { FC } from "react";
import { Form, FormikConfig, FormikProvider, useFormik } from "formik";
import { Button } from "src/components/Button";
import { ButtonVariants } from "src/components/Button/types";
import { RenderFormFields } from "src/components/RenderFormFields";
import { Sizes } from "src/@types/sizes";
import {
  RESET_PASSWORD_FORM_FIELDS,
  RESET_PASSWORD_FORM_VALIDATION_SCHEMA,
  RESET_PASSWORD_INITIAL_VALUES,
} from "./constants";
import { IFormikValues } from "./types";

export const ResetPassword: FC = () => {
  const formikProps: FormikConfig<IFormikValues> = {
    initialValues: RESET_PASSWORD_INITIAL_VALUES,
    validationSchema: RESET_PASSWORD_FORM_VALIDATION_SCHEMA,
    onSubmit: (values) => {
      console.log(values);
    },
  };

  const formik = useFormik(formikProps);

  return (
    <FormikProvider value={formik}>
      <Form className="mt-10">
        <div className="flex gap-6">
          <RenderFormFields fields={RESET_PASSWORD_FORM_FIELDS} />
        </div>

        <Button
          className="mt-10"
          size={Sizes.S}
          variant={ButtonVariants.PRIMARY}
          type="submit"
        >
          Reset Password
        </Button>
      </Form>
    </FormikProvider>
  );
};
